import { useSettingsSelector } from "@/hooks/settings/use-settings-selector";
import { ProjectTree } from "../services/project.service";
import { useMetadata } from "./useMetadata"
import { useTree } from "./useTree";
import { RolesEnum, useUserStore } from "@/stores/userStore";
import { useUserSecurityGroup } from "./useUserSecurityGroup";
import { IUser } from "../services/user.service";


export const useHiddenLayers = () => {
    const { metadata, isLoading: isMetaLoading, refetch: refetchMeta } = useMetadata()
    const { layers, isLoading: isTreeLoading, refetch: refetchTree } = useTree()
    const { role, user } = useUserStore()
    const { value: userGroup, isLoading: isGroupLoading, refetch: refetchGroup } = useUserSecurityGroup(user?.user_id ?? null)
    const securityGroups = useSettingsSelector(({ securityGroups }) => securityGroups) ?? {}

    const isLoading = isMetaLoading || isTreeLoading || isGroupLoading
    const refetch = () => void Promise.all([refetchMeta(), refetchTree(), refetchGroup()])
    const byRole = isHiddenByRole(metadata?.layers ?? {}, role);
    const bySecurityGroup = isHiddenBySecurityGroup(securityGroups, userGroup)
    const isHidden = isHiddenTree(byRole, bySecurityGroup);
    const hiddenLayers = (metadata && layers) ? extractHiddenTrees(isHidden, layers.tree) : void 0
    return { isLoading, refetch, hiddenLayers }
}

const isHiddenTree = (...strategies: ((tree: ProjectTree) => boolean)[]) => (tree: ProjectTree) =>
    strategies.some(strategy => strategy(tree))

const isHiddenBySecurityGroup = (securityGroups: Record<string, { hiddenLayers?: number[] }>, userGroup: string) => {
    const hiddenLayers = securityGroups[userGroup]?.hiddenLayers ?? []
    return ({ id }: ProjectTree): boolean => hiddenLayers.includes(id)
}

const isHiddenByRole = (layersMetadata: any, role: RolesEnum) => (tree: ProjectTree): boolean => {
    if (role == RolesEnum.Admin) return false;
	const plugins = layersMetadata[tree.type_uid]?.plugin_data ?? {}
	const fields = Object.values(plugins).find((v: any) => v.fields)?.['fields'] ?? []
	const hideProp = fields.find((prop: any) => prop.name === '#hide')
	if (!hideProp) return false
	return Object.values(tree.plugin_data)
        .reduce((result: any[], pluginRows) => result.concat(pluginRows), [])
        .some(({ field_id, value }) => field_id == hideProp.id && value)
}

const extractHiddenTrees = (isHidden: (tree: ProjectTree) => boolean, trees: ProjectTree[]): ProjectTree[] => 
    trees.reduce((result: ProjectTree[], tree) => result.concat(
        isHidden(tree) 
            ? flatChildren(tree) 
            : extractHiddenTrees(isHidden, tree.childs)
    ), [])

const flatChildren = (tree: ProjectTree, usedIds: number[] = []): ProjectTree[] => {
    if (usedIds.includes(tree.id)) return []
    return tree.childs
		.map((child) => flatChildren(child, [...usedIds, tree.id]))
		.flat()
		.concat([tree])
}