import { create } from 'zustand'

type BookingState = {
	week: Date
	offset: number
	setWeek: (week: Date) => void
}

export const useBookingStore = create<BookingState>((set) => ({
	week: new Date(),
	offset: new Date().getTimezoneOffset() / 30,
	setWeek: (week) => set(() => ({ week })),
}))
