import { ProjectTree } from "@/api/project";

export const excludeFromTrees = (itemsOrIds: (ProjectTree | number)[]) =>
    excludeIds(itemsOrIds.map(toId))

const toId = (itemOrId: ProjectTree | number) => typeof itemOrId === "object" ? itemOrId.id : itemOrId

const excludeIds = (ids: number[]) => (trees: ProjectTree[]): ProjectTree[] => 
    trees
        .filter(({ id }) => !ids.includes(id))
        .map(({ childs, ...tree }) => ({ ...tree, childs: excludeIds(ids)(childs) }))