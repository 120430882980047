import React from 'react'
import styled from 'styled-components'
import { ProjectTree } from '@/api/project';
import TreeItem from './TreeItem';
import TreeList from './TreeList';
import { useHiddenLayers } from '@/api/hooks/useHiddenLayers';
import { excludeFromTrees } from '@/utils/helpers/tree.helpers';

const TreeView: React.FC<{ data: ProjectTree[] }> = ({ data }) => {

    const { hiddenLayers } = useHiddenLayers();

    if (!data || !data.length || !hiddenLayers) return null

    data = excludeFromTrees(hiddenLayers)(data);

    return (
        <ListContainer>
            <TreeList $expanded={true}>
                {data.map(item => <TreeItem tree={item} key={item.id} />)}
            </TreeList>
        </ListContainer>
    )
}

export default TreeView

const ListContainer = styled.div`
    padding: 0.8rem 0;
    overflow-y: auto;
    height: 100%;
`