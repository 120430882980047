import { RuntimeFieldData } from "@/api/services/user.service";
import { useUser } from "./useUser";
import { useUserCardFields } from "./useUserCardFields";

export const useUserPluginValuesBy = 
    <T extends keyof RuntimeFieldData>(key: T) =>
    (keyValue: RuntimeFieldData[T]) => 
    (user_id: number | null) => {
        const { data: user, isLoading: isUserLoading, refetch: refetchUser } = useUser(user_id);
        const { data: fields, isLoading: isFieldsLoading, refetch: refetchFields } = useUserCardFields()        
        const isLoading = isUserLoading || isFieldsLoading
        const refetch = () => void Promise.all([refetchUser(), refetchFields()])
        const values = (user && fields) 
            ? pluginValues(user.user.plugin_data, uids(fields.fields, key, keyValue)) 
            : void 0
        return { isLoading, refetch, values }
    }

const uids = <T extends keyof RuntimeFieldData>(fields: RuntimeFieldData[], key: T, keyValue: RuntimeFieldData[T]) => 
    fields
        .filter((filed) => filed[key] === keyValue)
        .map(({ uid }) => uid)

const pluginValues = (plugin_data: Record<string, Record<string, string>>, uids: string[]) => 
    Object.values(plugin_data)
        .reduce((result: Record<string, string>[], plugin) => result.concat(plugin), [])
        .map(plugin => 
            Object.entries(plugin)
                .filter(([key]) => uids.includes(key))
                .map(([, value]) => value)
        )
        .flat()