import Grid from '@/ui/components/Grid'
import React from 'react'
import styled, { css } from 'styled-components'
import WeekSelector from './WeekSelector'
import { useFormikContext } from 'formik'
import GridAnnotation from './GridAnnotation'
import GridCalendar from './calendar-selector/GridCalendar'
import useResponsive from '@/hooks/useResponsive'

export type BookingSlot = {
	start: Date
	end: Date
}

type BookingGridProps = {
	bookings: any
	addItem: Function
}

const Tip = styled.div`
	margin: 8px 0 0 0;
	display: flex;
	align-items: center;
	border: 1px solid #009dab;
	border-radius: 8px;
	padding: 4px 16px;
`

const TipText = styled.div`
	font-size: 14px;
	margin-left: 8px;
`

const BookingGridSelector: React.FC<BookingGridProps> = ({ addItem }) => {
	const { isMobile } = useResponsive()
	const { values } = useFormikContext<any>()

	return (
		<>
			<WeekSelector visible={isMobile || values.type !== '2'} />

			<Tip>
				<div>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="32px"
						height="32px"
						viewBox="0 0 24 24"
						fill="none"
					>
						<div
							id="in-page-channel-node-id"
							data-channel-name="in_page_channel_oHeBAA"
						/>
						<path
							d="M12 7C12.5523 7 13 7.44772 13 8V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V8C11 7.44772 11.4477 7 12 7Z"
							fill="#009dab"
						/>
						<path
							d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
							fill="#009dab"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
							fill="#009dab"
						/>
					</svg>
				</div>
				<TipText>Выберите дату и время</TipText>
			</Tip>

			<CssGrid $full={isMobile}>
				{!isMobile && (
					<GridCols>
						<div />
						{Array.from({ length: 24 }).map((item, idx) => (
							<GridTime key={idx}>{`${idx}`.padStart(2, '0')}:00</GridTime>
						))}
					</GridCols>
				)}

				<GridCalendar addItem={addItem} />

				<Grid container>
					<Grid item xs={6} md={3}>
						<GridAnnotation color="#FD4F26" text="already-booked" />
					</Grid>
					<Grid item xs={6} md={3}>
						<GridAnnotation color="#F3BB5E" text="booked-by-me" />
					</Grid>
					<Grid item xs={6} md={3}>
						<GridAnnotation color="#c795f1" text="my-parallel-bookings" />
					</Grid>
					<Grid item xs={6} md={3}>
						<GridAnnotation color="#079DAC" text="current-booking" />
					</Grid>
					<Grid item xs={6} md={3}>
						<GridAnnotation color="#BFBFBF" text="free" />
					</Grid>
				</Grid>
			</CssGrid>
		</>
	)
}

export default BookingGridSelector

const CssGrid = styled.div<{ $full?: boolean }>`
	display: grid;
	row-gap: 6px;

	${({ $full }) =>
		$full
			? css`
					grid-template-rows: 1fr;
					margin-top: 20px;
			  `
			: css`
					grid-template-rows: 42px 1fr;
			  `}
`

const GridCols = styled.div`
	display: grid;
	grid-template-columns: 24px repeat(24, 1fr);
`
const GridTime = styled.div`
	font-size: 1rem;
	line-height: 1rem;
	color: #000000;
	display: inline-block;
	white-space: nowrap;
	transform: translate(0, 100%) rotate(-90deg);
	transform-origin: top left;
`
