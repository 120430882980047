import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import FormLoader from '@/components/ui/form/FormLoader'
import {
	bookingConstantSchema,
	bookingSchema,
} from '@/utils/validation/booking.validation'
import { Field, Formik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useBookingDialogStore } from './BookingModal'
import Grid from '@/ui/components/Grid'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import { SelectField } from '@/ui/components/Field/Select'
import {
	bookingErrors,
	bookingTypes,
} from '@/utils/constants/booking.constants'
import { useSeat } from '@/api/hooks/useSeat'
import BookingGridField from './booking-grid/BookingGridField'
import BookingIntervalField from './intervals/BookingIntervalsField'
import { SelectSeatField } from './select-seat/SelectSeatField'
import SelectUserField from './select-user/SelectUserField'
import { NodeInfo } from '@/api/services/booking.service'
import {
	addMinutes,
	eachWeekOfInterval,
	endOfWeek,
	getDay,
	parseISO,
	startOfWeek,
} from 'date-fns'
import { formatToISOTimezone } from '@/utils/helpers/dates.helpers'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { useProjectStore } from '@/stores/projectStore'
import { useBookingStore } from '@/stores/bookingStore'
import { API, redirectSAML } from '@/api'
import { useProject } from '@/hooks/useProject'
import { useQueryClient } from 'react-query'
import { StatusResponseEnum } from '@/api/types'
import { useToast } from '@/components/shared/toast/useToast'
import { FormControls } from '@/components/ui/form/FormControls'
import { useNavigate } from 'react-router-dom'
import WeeklySelector from './booking-grid/weekly-selector/WeeklySelector'
import GlobalIntervals from './intervals/GlobalIntervals'
import ParallelBookings from './ParallelBookings'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'
import toast from 'react-hot-toast'
import CloseIcon from '@/components/icons/CloseIcon'
import {
	ToastClose,
	ToastContent,
	ToastTitle,
	ToastWrapper,
} from '@/components/shared/toast/Toast'

type BookingFormProps = {
	data: any
	isLoading: boolean
}

const d = new Date()
const offset = d.getTimezoneOffset()

const formatBeforeSubmit = (time) => addMinutes(time, offset)

const getDate = (type, date) => {
	if (!type || !date) return ''

	return parseISO(date + '.000Z')
}

const BookingForm: React.FC<BookingFormProps> = ({ data, isLoading }) => {
	// data selectors
	const bookingId = useBookingDialogStore((state) => state.bookingId)
	const nodeId = useBookingDialogStore((state) => state.nodeId)
	const onClose = useBookingDialogStore((state) => state.onClose)
	const user = useUserStore((state) => state.user)
	const authRef = useUserStore((state) => state.authRef)
	const role = useUserStore((state) => state.role)
	const nodes = useProjectStore((state) => state.nodes)
	const setWeek = useBookingStore((state) => state.setWeek)
	const week = useBookingStore((state) => state.week)
	const intl = useIntl()

	const { data: seat, isLoading: isSeatLoading } = useSeat(nodeId)
	const { workspaceId, projectId } = useProject()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { enqueueToast } = useToast()
	const weekStart = startOfWeek(week, { weekStartsOn: 1 })
	const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

	const [validationSchema, setValidationSchema] = useState<any>(bookingSchema)

	let boundArrayHelpers
	const bindArrayHelpers = (arrayHelpers) => {
		boundArrayHelpers = arrayHelpers
	}

	useEffect(() => {
		if (!user || !role) {
			if (authRef) {
				onClose()
				redirectSAML(authRef, workspaceId, projectId)
			} else {
				onClose()
				navigate(`/login?workspace_id=${workspaceId}&project_id=${projectId}`)
				enqueueToast(
					{
						title: intl.formatMessage({ id: 'not-authorized' }),
						message: intl.formatMessage({ id: 'allowed-for-authorized' }),
					},
					{ variant: 'error' },
				)
			}
		}
	}, [user, role])

	useEffect(() => {
		if (!data || !data.booking) {
			setWeek(new Date())
		} else {
			const type = data.booking.booking_type

			// for recurrent books
			if (type == '2') {
				const weeks = eachWeekOfInterval(
					{
						start: formatToISOTimezone(data.booking.start),
						end: formatToISOTimezone(data.booking.end),
					},
					{ weekStartsOn: 1 },
				)

				const currentWeek = weeks.length > 1 ? weeks[1] : weeks[0]
				setWeek(currentWeek)

				return
			}

			if (data.booking.start) {
				setWeek(parseISO(data.booking.start + '.000Z'))
			} else if (data.booking.end) {
				setWeek(parseISO(data.booking.end + '.000Z'))
			} else {
				setWeek(new Date())
			}
		}
	}, [data])

	const getErrorMessage = (msg: string) => {
		const resolverMsg = bookingErrors[msg]
		if (resolverMsg) return resolverMsg
		if (msg.includes('field `intervals`: array is empty'))
			return 'Не выбран интервал для добавления'

		return intl.formatMessage({ id: 'internal-add-error' })
	}

	const handleFormSubmit = async (values, { setSubmitting }) => {
		setSubmitting(true)
		try {
			const bId = data?.booking.id ? Number(data.booking.id) : null
			const response = await API.bookings.add({
				bookingId: bId,
				workspaceId,
				projectId,
				resourceId: Number(values.seat.id),
				userId: RolesEnum.Admin === role ? Number(values.user.id) : null,
				bookingType: Number(values.type),
				dates: values.dates.map((item) => ({
					start: formatBeforeSubmit(item.start),
					end: formatBeforeSubmit(item.end),
				})),
				start: formatBeforeSubmit(values.start),
				end: formatBeforeSubmit(values.end),
			})

			if (response && response.status === StatusResponseEnum.Success) {
				toast.dismiss()
				queryClient.refetchQueries('bookings')
				queryClient.refetchQueries([
					'booking_intervals',
					workspaceId,
					projectId,
					Number(values.seat.id),
					RolesEnum.Admin === role ? Number(values.user.id) : null,
					weekStart,
					weekEnd,
				])
				queryClient.refetchQueries('bookings_for_layer')

				onClose()
				enqueueToast(
					{
						title: intl.formatMessage({ id: 'booking-added' }),
						message: intl.formatMessage({ id: 'booking-added-confirm' }),
					},
					{ variant: 'success' },
				)
			} else if (
				response &&
				response.status === StatusResponseEnum.NotAuthorized
			) {
				navigate(`/login?workspace_id=${workspaceId}&project_id=${projectId}`)
				enqueueToast(
					{
						title: intl.formatMessage({ id: 'not-authorized' }),
						message: intl.formatMessage({ id: 'allowed-for-authorized' }),
					},
					{ variant: 'error', timeout: Infinity },
				)
			} else {
				throw new Error(response?.error_info.message)
			}
		} catch (e: any) {
			const errorMessage = getErrorMessage(e.message)
			toast.dismiss()
			toast.custom(
				(t) => (
					<ToastWrapper
						className={`${t.visible ? 'animate-enter' : 'animate-leave'}`}
						variant="error"
					>
						<ToastTitle>{intl.formatMessage({ id: 'error' })}</ToastTitle>
						<ToastContent>{errorMessage}</ToastContent>
						<ToastClose onClick={() => toast.dismiss(t.id)}>
							<CloseIcon />
						</ToastClose>
					</ToastWrapper>
				),
				{ duration: Infinity },
			)
			// enqueueToast(
			// 	{
			// 		title: intl.formatMessage({ id: 'error' }),
			// 		message: errorMessage,
			// 	},
			// 	{ variant: 'error' },
			// )
		}
		setSubmitting(false)
	}

	const bookingOptions = useMemo(
		() =>
			bookingTypes
				.map((item) => ({
					...item,
					label: translate(item.label),
				}))
				.filter((item) => item.value !== '3'),
		[bookingTypes],
	)

	return (
		<Formik
			validationSchema={validationSchema}
			onSubmit={handleFormSubmit}
			enableReinitialize
			initialValues={{
				type: String(data?.booking?.booking_type || 1) || '1',
				seat: {
					id: data?.node?.id || seat?.node.id || '',
					name: getSeatName(nodes, data?.node || seat?.node).join('. '),
				},
				user: {
					id: data?.booking?.user_id || user?.user_id || '',
					name: data?.booking?.user_name || user?.display || '',
				},
				dates: data?.slots || [],
				start: getDate(data?.booking?.booking_type, data?.booking.start),
				end: getDate(data?.booking?.booking_type, data?.booking.end),
				weekly: data?.slots[0] || {
					start: '',
					end: '',
				},
				days: data?.slots?.map((slot) => getDay(slot.start)) || [],
			}}
		>
			{({ handleSubmit, values, isSubmitting, setFieldValue }) => (
				<form onSubmit={handleSubmit}>
					<FormContainer>
						<Grid container>
							<SelectSeatField />
							<SelectUserField />
						</Grid>

						<Grid container>
							<Grid item xs={12} md={4}>
								<FormLabel>{translate('booking-type')}</FormLabel>
								<Field
									name="type"
									$fullWidth
									required={true}
									placeholder={intl.formatMessage({ id: 'booking-type' })}
									component={SelectField}
									options={bookingOptions}
									disabled={!!bookingId}
									style={{ opacity: !!bookingId ? 0.5 : 1 }}
									onChange={(e) => {
										const type = e.target.value

										if (type == 1) {
											setValidationSchema(bookingSchema)
										} else {
											setValidationSchema(bookingConstantSchema)
										}

										if (type == 3) {
											setFieldValue('dates', [])
										}

										setFieldValue('type', e.target.value)
									}}
								/>
							</Grid>
						</Grid>

						<GlobalIntervals />

						{values.type === '2' && <WeeklySelector />}

						<BookingIntervalField bindArrayHelpers={bindArrayHelpers} />

						<BookingGridField
							bookings={data}
							addItem={(value) => boundArrayHelpers.push(value)}
						/>
					</FormContainer>

					<ControlsWrapper>
						<FormControls>
							<PrimaryButton $fullWidth type="submit" disabled={isSubmitting}>
								{bookingId ? translate('save') : translate('submit')}
							</PrimaryButton>
							<DefaultButton $fullWidth type="button" onClick={onClose}>
								{translate('cancel')}
							</DefaultButton>
						</FormControls>
					</ControlsWrapper>

					<ParallelBookings />

					<FormLoader isLoading={isLoading || isSeatLoading} />
				</form>
			)}
		</Formik>
	)
}

const FormContainer = styled.div`
	padding: 1rem 0;
	max-height: 100%;
`

export default BookingForm

const ControlsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`

const getSeatName = (nodes: any, node: NodeInfo | undefined) => {
	if (!node) return []
	const currentNode = nodes.find((tree) => Number(tree.id) === node.parent)
	return [currentNode?.name, node.name]
}
