import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './components/layout/GlobalStyles'
import { Reset } from './components/layout/Reset'
import AppContainer from './containers/AppContainer'
import blueTheme from './ui/blueTheme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import ReactQueryProvider from '@/lib/react-query'
import ToastProvider from './components/shared/toast/ToastProvider'
import ConfirmDialog from './components/ConfirmDialog'
import React, { useLayoutEffect } from 'react'
import { LocaleProvider, LOCALES } from './i18n'
import en from 'date-fns/locale/en-GB'
import ru from 'date-fns/locale/ru'
import ModalRoot from '@/components/shared/modal/ModalRoot'
import { Portal } from 'react-portal'

import '@/assets/styles/index.css'
import { Toaster } from 'react-hot-toast'

const selectedLocale = LOCALES.RUSSIAN
export const dateFnsLocale = selectedLocale == LOCALES.ENGLISH ? en : ru

const App = () => {
	useLayoutEffect(() => {
		const appHeight = () => {
			document.documentElement.style.setProperty(
				'--app-height',
				`${window.innerHeight}px`,
			)
		}
		appHeight()

		window.addEventListener('resize', appHeight)

		return () => window.removeEventListener('resize', appHeight)
	}, [])

	return (
		<LocaleProvider locale={selectedLocale}>
			<ThemeProvider theme={blueTheme}>
				<ToastProvider>
					<ReactQueryProvider>
						{/* Reset styling */}
						<Reset />
						<GlobalStyles />

						{/* Main Application */}
						<AppContainer />
						<ConfirmDialog />
						<ModalRoot />
					</ReactQueryProvider>
				</ToastProvider>
			</ThemeProvider>

			<Portal node={document && document.getElementById('toaster')}>
				<Toaster position="bottom-right" reverseOrder={false} />
			</Portal>
		</LocaleProvider>
	)
}

export default App
